import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import { SubPageLayout } from '../components/SubPageLayout'
import { CTAContactForm } from '../helpers'

export const PageTemplate = ({ content, isPreview = false }) => (
  <SubPageLayout>
    {!isPreview && <CTAContactForm />}
    <HTMLContent content={content} />
    <br />
  </SubPageLayout>
)

const Page = ({ data, pageContext }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout
      withHeader={{ ...frontmatter, ...pageContext }}
      pageContext={pageContext}
    >
      <PageTemplate content={html} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default Page

export const productPageQuery = graphql`
  query Page($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`
